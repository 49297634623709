import ComponentsModule from './components'
import ModelsModule from './models'
import ControllersModule from './controllers'
import FiltersModule from './filters'
import ServicesModule from './services'
import StatesModule from './states'

import ngSanitize from 'angular-sanitize'
import ngMessages from 'angular-messages'
import ngFileSaver from 'angular-file-saver'
import 'angularjs-toaster'
import 'ui-select'
import '@uirouter/angularjs'
import 'angular-popeye'
import 'angular-modal-service'
import '../style/style.scss'
import { settings } from './app.config'
import angular from 'angular'
import { KeycloakService } from './services/keycloak.service'

// К сожалению, кейклок пока что не поддерживает настраиваемые
// заголовки CORS, поэтому сентри подключить не так просто.
// Временно отключил:
// https://github.com/keycloak/keycloak/issues/12682

// import * as Sentry from '@sentry/browser'
// import { BrowserTracing } from '@sentry/tracing'
// Sentry.init({
//   dsn: settings.sentry.dsn,
//   environment: settings.environment,

//   // Alternatively, use `process.env.npm_package_version`
//   // for a dynamic release version
//   // if your build tool supports it.
//   release: settings.version,
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: settings.sentry.tracesSampleRate,
// })

// хитромудрый способ инициализировать сервис с помощью
// промиса через приложение-инициализатор, которое резолвит
// всех необходимые промисы, а затем загружает основное
// приложение
angular
  .module('appInitializer', [])
  .constant('keycloak', null)
  .service('keycloakService', KeycloakService)
  .factory('initializer', (keycloakService, $document) => {
    return keycloakService.init().then(
      (authenticated) => {
        angular.module('gruz').constant('keycloak', keycloakService.instance)
        $document.ready(() => {
          angular.bootstrap($document.find('body'), ['gruz'])
        })
      },
      (error) => {
        console.log(error)
      }
    )
  })

angular
  .injector(['ng', 'appInitializer'])
  .get('initializer')
  .catch((err) => console.error(err))

const app = angular.module('gruz', [
  'ui.select',
  'ui.router',
  'pathgather.popeye',
  'angularModalService',
  'toaster',
  ngFileSaver,
  ngMessages,
  ngSanitize,
  StatesModule,
  ComponentsModule,
  ControllersModule,
  ModelsModule,
  FiltersModule,
  ServicesModule,
])

app.constant('config', settings)
app.config(appConfig)

app.run(appRun)

function appConfig($httpProvider, uiSelectConfig) {
  uiSelectConfig.theme = 'select2'
  uiSelectConfig.resetSearchInput = true

  $httpProvider.interceptors.push('authHttpInterceptorService')
  $httpProvider.interceptors.push('busyCursorHttpInterceptor')
  $httpProvider.interceptors.push('toasterHttpInterceptor')
}

function appRun($rootScope, $templateCache, $transitions, keycloakService) {
  $rootScope.settings = settings

  // загрузка шаблонов сообщений об ошибках для полей форм
  $templateCache.put(
    'error-messages',
    require('./templates/error-messages.html')
  )

  $transitions.onSuccess({}, function ($transition) {
    // подстановка заголовка страницы из состояния
    const title = $transition.$to().data.title
    if (typeof title === 'function') {
      $rootScope.title = title($transition.targetState())
    } else {
      $rootScope.title = title
    }
  })

  $transitions.onBefore({}, function (transition) {
    // check if the state should be protected
    // if transition.to().protected
    if (!keycloakService.authenticated) {
      const stateService = transition.router.stateService
      const redirectUri = stateService.href(
        transition.to().name,
        transition.params(),
        { absolute: true }
      )
      keycloakService.login({ redirectUri })
      // return false
    }
  })
}
