/** @ngInject */
class SelectAlphabetController {
  constructor($scope) {
    this.$scope = $scope
    this.letters_ru_str = 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧЪЫЬЭЮЯ'
    this.letters_en_str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  }

  select(letter) {
    this.ngModel.$setViewValue(letter)
  }
}

export const SelectAlphabetComponent = {
  template: require('./select-alphabet.component.html'),
  styles: [require('./select-alphabet.component.scss')],
  bindings: {},
  require: {
    ngModel: '^ngModel',
  },
  controller: SelectAlphabetController,
  controllerAs: 'vm',
}
